<template>
    <v-container fluid fill-height app class="d-flex align-start">
        <v-sheet height="100%" width="100%" class="rounded-lg">
            <v-toolbar flat class="rounded-lg">
                <div class="d-flex align-start mr-4">
                    <v-icon x-large color="primary">
                        {{ tableIcon }}
                    </v-icon>
                </div>
                <div class="d-flex flex-column align-start">
                    <span class="text-h6 font-weight-regular"> {{ tableName }}</span>
                </div>

                <v-spacer></v-spacer>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="primary" dark
                            v-on="on"
                            class="mb-2 pa-0"
                            min-width="48px"
                            @click="$router.push('/')"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Fechar</span>
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mx-4 mb-2"></v-divider>

            <v-container fluid>
                <v-stepper v-model="step" class="elevation-0">
                    <v-stepper-header class="elevation-0">
                        <v-stepper-step
                            :complete="step > 1"
                            step="1"
                        >
                            Assistente de criação/ alteração de evento
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            :complete="step > 2"
                            step="2"
                        >
                            Evento principal
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            :complete="step > 3"
                            step="3"
                        >
                            Eventos de cada tela
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step step="4">
                            Calendário e status dos eventos
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content step="1" class="py-0">
                            <v-sheet class="px-4">
                                <v-radio-group v-model="radioGroup" class="mt-4">
                                    <v-radio
                                        label="Criar um evento principal"
                                        :value="1"
                                        class="ma-0 mr-6 pa-0"
                                        hide-details
                                    ></v-radio>

                                    <div class="d-flex align-center mt-8">
                                        <v-radio
                                            label="Alterar evento principal"
                                            :value="2"
                                            class="ma-0 pa-0 mr-6"
                                            hide-details
                                        ></v-radio>

                                        <v-autocomplete
                                            v-model="selectedMainEvent"
                                            label="Evento"
                                            :items="eventsAssistant"
                                            :disabled="radioGroup !== 2"
                                            style="max-width: 400px;"
                                            class="ma-0 pa-0"
                                            hide-details
                                            :loading="loadingEvent"
                                            return-object
                                        ></v-autocomplete>
                                    </div>

                                    <v-scroll-y-transition>
                                        <span v-if="radioGroup === 2" class="text-caption ml-2 mt-4">
                                            Alterações só são possíveis caso o evento ainda não tenha sido iniciado.
                                            A alteração irá apagar e recriar os eventos de cada tela.
                                        </span>
                                    </v-scroll-y-transition>
                                </v-radio-group>
                            </v-sheet>

                            <v-divider class="my-6"></v-divider>

                            <div class="d-flex">
                                <span class="text-caption primary--text ">* Campos obrigatórios</span>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    @click="begin()"
                                    :disabled="radioGroup === 2 && !selectedMainEvent"
                                    :loading="starting"
                                >
                                    Iniciar
                                </v-btn>
                            </div>
                        </v-stepper-content>

                        <v-stepper-content step="2" class="py-0">
                            <v-sheet class="px-4 pb-4">
                                <v-row class="ma-0 pa-0">
                                    <v-col class="ma-0 pa-0 pr-4" cols="6">
                                        <v-autocomplete
                                            v-model="selectedCompanyGroup"
                                            label="Grupo empresarial *"
                                            :items="companyGroups"
                                            :loading="loadingCompanyGroup"
                                            return-object
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>

                                <v-row class="ma-0 pa-0">
                                    <v-col class="ma-0 pa-0 pr-4" cols="3">
                                        <v-autocomplete
                                            v-model="selectedEventType"
                                            label="Tipo do evento *"
                                            :items="eventTypes"
                                            :loading="loadingEventType"
                                            return-object
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col class="ma-0 pa-0 pr-4" cols="3">
                                        <v-autocomplete
                                            v-model="selectedFiscalYear"
                                            label="Ano fiscal *"
                                            :items="fiscalYears"
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col class="ma-0 pa-0 d-flex" cols="6">
                                        <v-text-field class="pr-4"
                                            v-model="version"
                                            type="text"
                                            label="Versão"
                                            hint="Ex: v1, 0.1"
                                            persistent-hint
                                        ></v-text-field>

                                        <v-autocomplete
                                            v-model="selectedYearMonth"
                                            label="Mês de início do input dos dados *"
                                            :items="yearMonths"
                                            hint="Eventos de Forecast serão completados com o realizado anterior"
                                            persistent-hint
                                            :disabled="!selectedFiscalYear"
                                            :loading="loadingYearMonth"
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>

                                <v-row class="ma-0 pa-0 mt-4 d-flex flex-column">
                                    <v-col class="ma-0 pa-0" cols="6">
                                        <div class="d-flex align-center">
                                            <!-- <v-checkbox
                                                v-model="copyEventConfig"
                                                label="Copiar configurações do evento"
                                                :value="1"
                                                class="ma-0 pa-0 mr-6"
                                                hide-details
                                            ></v-checkbox> -->

                                            <span class="ma-0 pa-0 mr-6 text--secondary">Copiar configurações do evento</span>

                                            <v-autocomplete
                                                v-model="selectedCopyConfigEvent"
                                                label="Evento *"
                                                :items="events"
                                                hide-details
                                                class="ma-0 pa-0"
                                                return-object
                                                :disabled="!selectedFiscalYear"
                                            ></v-autocomplete>
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row class="ma-0 pa-0 mt-8 d-flex flex-column">
                                    <v-col class="ma-0 pa-0" cols="6">
                                        <div
                                            class="d-flex align-center"
                                            :class="{ 'mb-4': copyEventValuesYear1 }"    
                                        >
                                            <v-checkbox
                                                v-model="copyEventValuesYear1"
                                                label="Copiar valores do evento ano 1"
                                                :value="1"
                                                class="ma-0 pa-0 mr-6"
                                                hide-details
                                                @change="setCopyEventValueYear1($event)"
                                            ></v-checkbox>

                                            <v-autocomplete
                                                v-model="selectedCopyValuesEventYear1"
                                                label="Evento"
                                                :items="events"
                                                hide-details
                                                class="ma-0 pa-0"
                                                @change="setCopyEventYear1($event)"
                                                :disabled="!copyEventValuesYear1"
                                                return-object
                                            ></v-autocomplete>
                                        </div>

                                        <v-scroll-y-transition>
                                            <span v-if="copyEventValuesYear1" class="text-caption ml-2">
                                                Se o evento for de ano fiscal diferente o ano será ignorado e será considerado apenas os meses. Jan para jan, fev para fev, etc
                                            </span>
                                        </v-scroll-y-transition>
                                    </v-col>
                                </v-row>

                                <v-row class="ma-0 pa-0 mt-8 d-flex flex-column">
                                    <v-col class="ma-0 pa-0" cols="6">
                                        <div
                                            class="d-flex align-center"
                                            :class="{ 'mb-4': copyEventValuesYear2 }"    
                                        >
                                            <v-checkbox
                                                v-model="copyEventValuesYear2"
                                                label="Copiar valores do evento ano 2"
                                                :value="1"
                                                class="ma-0 pa-0 mr-6"
                                                hide-details
                                                @change="setCopyEventValueYear2($event)"
                                            ></v-checkbox>

                                            <v-autocomplete
                                                v-model="selectedCopyValuesEventYear2"
                                                label="Evento"
                                                :items="events"
                                                hide-details
                                                class="ma-0 pa-0"
                                                @change="setCopyEventYear2($event)"
                                                :disabled="!copyEventValuesYear2"
                                                return-object
                                            ></v-autocomplete>
                                        </div>

                                        <v-scroll-y-transition>
                                            <span v-if="copyEventValuesYear2" class="text-caption ml-2">
                                                Se o evento for de ano fiscal diferente o ano será ignorado e será considerado apenas os meses. Jan para jan, fev para fev, etc
                                            </span>
                                        </v-scroll-y-transition>
                                    </v-col>
                                </v-row>

                                <v-row class="ma-0 pa-0 mt-9">
                                    <div class="d-flex align-center">
                                        <v-checkbox
                                            v-model="isScenarioSimulation"
                                            label="Este novo evento é uma simulação de cenário do evento selecionado para copia dos valores"
                                            :value="1"
                                            class="ma-0 pa-0 mr-6"
                                            hide-details
                                        ></v-checkbox>
                                    </div>
                                </v-row>
                            </v-sheet>

                            <v-divider class="my-6"></v-divider>

                            <div class="d-flex">
                                <span class="text-caption primary--text ">* Campos obrigatórios</span>
                                <v-spacer></v-spacer>
                                <v-btn text
                                    class="mr-4 primary--text"
                                    @click="step = 1"
                                >
                                    Voltar
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    @click="step = 3"
                                    :disabled="disableStep2NextButton"
                                >
                                    Próximo
                                </v-btn>
                            </div>
                        </v-stepper-content>

                        <v-stepper-content step="3" class="py-0">
                            <v-sheet class="px-4 pb-4">
                                <v-data-table
                                    :headers="step3Headers"
                                    :items="step3Items"
                                    :items-per-page="-1"
                                    class="ma-0 pa-0 elevation-0"
                                    hide-default-footer	
                                >
                                    <!-- eslint-disable-next-line -->
                                    <template v-slot:item.copy_config="props">
                                        <v-simple-checkbox
                                            color="primary"
                                            :value="props.item.copy_config"
                                            @input="props.item.copy_config = !props.item.copy_config"
                                            :ripple="false"
                                        >
                                        </v-simple-checkbox>
                                    </template>

                                    <!-- eslint-disable-next-line -->
                                    <template v-slot:item.copy_event_values_year_1="props">
                                        <v-simple-checkbox
                                            color="primary"
                                            :value="props.item.copy_event_values_year_1"
                                            @input="props.item.copy_event_values_year_1 = !props.item.copy_event_values_year_1"
                                            :ripple="false"
                                            :disabled="!copyEventValuesYear1"
                                        >
                                        </v-simple-checkbox>
                                    </template>

                                    <!-- eslint-disable-next-line -->
                                    <template v-slot:item.copy_event_year_1="props">
                                        <v-autocomplete
                                            v-model="props.item.copy_event_year_1"
                                            :items="events"
                                            hide-details
                                            class="ma-0 pa-0"
                                            return-object
                                            :disabled="!copyEventValuesYear1 || !props.item.copy_event_values_year_1"
                                        ></v-autocomplete>
                                    </template>

                                    <!-- eslint-disable-next-line -->
                                    <template v-slot:item.copy_event_values_year_2="props">
                                        <v-simple-checkbox
                                            color="primary"
                                            :value="props.item.copy_event_values_year_2"
                                            @input="props.item.copy_event_values_year_2 = !props.item.copy_event_values_year_2"
                                            :ripple="false"
                                            :disabled="!copyEventValuesYear2"
                                        >
                                        </v-simple-checkbox>
                                    </template>

                                    <!-- eslint-disable-next-line -->
                                    <template v-slot:item.copy_event_year_2="props">
                                        <v-autocomplete
                                            v-model="props.item.copy_event_year_2"
                                            :items="events"
                                            hide-details
                                            class="ma-0 pa-0"
                                            return-object
                                            :disabled="!copyEventValuesYear2"
                                        ></v-autocomplete>
                                    </template>
                                </v-data-table>

                                <v-divider class="my-6"></v-divider>

                                <div class="d-flex">
                                    <span class="text-caption primary--text ">* Campos obrigatórios</span>
                                    <v-spacer></v-spacer>
                                    <v-btn text
                                        class="mr-4 primary--text"
                                        @click="step = 2"
                                    >
                                        Voltar
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        @click="step = 4"
                                    >
                                        Próximo
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-stepper-content>

                        <v-stepper-content step="4" class="py-0">
                            <v-sheet class="px-4 pb-4">
                                <v-data-table
                                    :headers="step4Headers"
                                    :items="step4Items"
                                    :items-per-page="-1"
                                    class="ma-0 pa-0 elevation-0"
                                    hide-default-footer	
                                >
                                    <!-- eslint-disable-next-line -->
                                    <template v-slot:item.start_date="props">
                                        <v-menu :ref="`menu${props.header.value}${props.index}`"
                                            :close-on-content-click="false"
                                            :return-value.sync="props.item.start_date"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <div class="d-flex">
                                                    <v-text-field
                                                        :value="formatDate(props.item.start_date)"
                                                        hide-details
                                                        class="ma-0 pa-0 mr-2"
                                                        @input="setInputDate(props, $event)"
                                                        maxlength="10"
                                                    ></v-text-field>
                                                    <v-icon v-on="on">
                                                        mdi-calendar
                                                    </v-icon>
                                                </div>
                                            </template>
                                            <v-date-picker
                                                v-model="props.item.start_date"
                                                no-title
                                                scrollable
                                                color="primary"
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs[`menu${props.header.value}${props.index}`].save(props.item.start_date)"
                                                >
                                                    Selecionar
                                                </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </template>

                                    <!-- eslint-disable-next-line -->
                                    <template v-slot:item.end_date="props">
                                        <v-menu :ref="`menu${props.header.value}${props.index}`"
                                            :close-on-content-click="false"
                                            :return-value.sync="props.item.end_date"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <div class="d-flex">
                                                    <v-text-field
                                                        :value="formatDate(props.item.end_date)"
                                                        hide-details
                                                        class="ma-0 pa-0 mr-2"
                                                        @input="setInputDate(props, $event)"
                                                        maxlength="10"
                                                    ></v-text-field>
                                                    <v-icon v-on="on">
                                                        mdi-calendar
                                                    </v-icon>
                                                </div>
                                            </template>
                                            <v-date-picker
                                                v-model="props.item.end_date"
                                                no-title
                                                scrollable
                                                color="primary"
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs[`menu${props.header.value}${props.index}`].save(props.item.end_date)"
                                                >
                                                    Selecionar
                                                </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </template>

                                    <!-- eslint-disable-next-line -->
                                    <template v-slot:item.status="props">
                                        <v-autocomplete
                                            v-model="props.item.status"
                                            :items="status"
                                            item-text="text"
                                            return-object
                                            no-data-text="Não há dados disponíveis"
                                            hide-details
                                            class="ma-0 pa-0"
                                            :loading="loadingStatus"
                                        ></v-autocomplete>
                                    </template>
                                </v-data-table>

                                <v-divider class="my-6"></v-divider>

                                <div class="d-flex">
                                    <span class="text-caption primary--text ">* Campos obrigatórios</span>
                                    <v-spacer></v-spacer>
                                    <v-btn text
                                        class="mr-4 primary--text"
                                        @click="step = 3"
                                    >
                                        Voltar
                                    </v-btn>

                                    <v-btn
                                        color="primary"
                                        @click="submit()"
                                        :loading="submiting"
                                        :disabled="!!disableSubmit"
                                    >
                                        Finalizar
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-container>
        </v-sheet>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'Consolidation',

    components: { },

    computed: {
        ...mapGetters('auth', ['getMenu']),

        tableName() {
            const tableName = this.getTableInfo().tableName
            return tableName ? tableName : 'Erro ao listar a tabela'
        },

        tableIcon() {
            const tableIcon = this.getTableInfo().tableIcon
            return tableIcon ? tableIcon : 'mdi-view-list'
        },
        
        disableStep2NextButton() {
            return !this.selectedCompanyGroup || !this.selectedEventType
                || !this.selectedFiscalYear || !this.selectedYearMonth
                || (!this.selectedCopyConfigEvent)
                || (this.copyEventValuesYear1 && !this.selectedCopyValuesEventYear1)
                || (this.copyEventValuesYear2 && !this.selectedCopyValuesEventYear2)
        },

        disableSubmit() {
            return this.step4Items.filter(i => !i.start_date || !i.end_date || !i.status).length
        }
    },

    watch: {
        step() {
            if (this.step == 3) {
                let yearText = this.selectedEventType.id === 2 || this.selectedEventType.id === 3 ? `${this.selectedYearMonth.toString().substr(0, 4)}-${this.selectedYearMonth.toString().substr(4, 6)}` : this.selectedFiscalYear
                let text = `${this.selectedEventType.text || ''} ${yearText || ''} ${this.version || ''}`

                this.step3Items.forEach(i => {
                    i.event_description = `${i.account_group_version_description} ${text}`
                })

                this.step4Items.forEach(i => {
                    i.event_description = `${i.account_group_version_description} ${text}`
                })
            }

            if (this.step == 4) {
                const foundOpenStatus = this.status.find(i => i.id === 1)
                
                this.step4Items.forEach(i => {
                    if (!i.status) i.status = foundOpenStatus
                })
            }
        },

        selectedFiscalYear() {
            if (this.selectedFiscalYear) {
                this.selectedYearMonth = null
                this.getYearMonths()

                this.setSelectedCopyConfigEvent()
            }
        }
    },

    data() {
        return {
            submiting: false,
            starting: false,
            
            step: 1,
            radioGroup: 1,

            eventsAssistant: [],
            selectedMainEvent: null,
            loadingEventAssistant: false,

            events: [],
            loadingEvent: false,

            companyGroups: [],
            selectedCompanyGroup: null,
            loadingCompanyGroup: false,

            eventTypes: [],
            selectedEventType: null,
            loadingEventType: false,

            fiscalYears: [],
            selectedFiscalYear: null,
            loadingFiscalYear: false,

            yearMonths: [],
            selectedYearMonth: null,
            loadingYearMonth: false,

            version: null,

            selectedCopyConfigEvent: null,

            copyEventValuesYear1: false,
            selectedCopyValuesEventYear1: null,

            copyEventValuesYear2: false,
            selectedCopyValuesEventYear2: null,

            isScenarioSimulation: false,

            status: [],
            loadingStatus: false,

            step3Headers: [
                {
                    text: 'Módulo',
                    align: 'start',
                    sortable: false,
                    value: 'module_description',
                },
                {
                    text: 'Tela de planejamento',
                    align: 'start',
                    sortable: false,
                    value: 'planning_function',
                },
                {
                    text: 'Descrição da versão da conta gerencial',
                    align: 'start',
                    sortable: false,
                    value: 'account_group_version_description',
                },
                {
                    text: 'Descrição do evento',
                    align: 'start',
                    sortable: false,
                    value: 'event_description',
                },
                {
                    text: 'Copiar valores ano 1',
                    align: 'center',
                    sortable: false,
                    value: 'copy_event_values_year_1',
                },
                {
                    text: 'Evento ano 1',
                    align: 'center',
                    sortable: false,
                    value: 'copy_event_year_1',
                },
                {
                    text: 'Copiar valores ano 2',
                    align: 'center',
                    sortable: false,
                    value: 'copy_event_values_year_2',
                },
                {
                    text: 'Evento ano 2',
                    align: 'center',
                    sortable: false,
                    value: 'copy_event_year_2',
                },
            ],

            step3Items: [],

            step4Headers: [
                {
                    text: 'Módulo',
                    align: 'start',
                    sortable: false,
                    value: 'module_description',
                },
                {
                    text: 'Tela de planejamento',
                    align: 'start',
                    sortable: false,
                    value: 'planning_function',
                },
                {
                    text: 'Descrição da versão da conta gerencial',
                    align: 'start',
                    sortable: false,
                    value: 'account_group_version_description',
                },
                {
                    text: 'Descrição do evento',
                    align: 'start',
                    sortable: false,
                    value: 'event_description',
                },
                {
                    text: 'Data início ',
                    align: 'start',
                    sortable: false,
                    value: 'start_date',
                },
                {
                    text: 'Data término',
                    align: 'start',
                    sortable: false,
                    value: 'end_date',
                },
                {
                    text: 'Status',
                    align: 'start',
                    sortable: false,
                    value: 'status',
                },
            ],

            step4Items: [],
        }
    },

    created() {
        this.getEventsAssistant()
        this.getEvents()
        this.getCompanyGroups()
        this.getEventTypes()
        this.getFiscalYears()
        this.getStatus()
        this.getStepsItems()
    },

    methods: {
        setSelectedCopyConfigEvent() {
            let found = this.events.find(i => i.text === `ACTUAL-${this.selectedFiscalYear}`)
            if (found) {
                this.selectedCopyConfigEvent = found
            } else {
                this.selectedCopyConfigEvent = null
            }
        },

        async begin() {
            this.starting = true
            
            if (this.radioGroup === 2) {
                const event = this.messages.find(i => i.id_message === this.selectedMainEvent.id)
                const message = JSON.parse(event.message)

                const foundCompanyGroup = this.companyGroups.find(i => i.id === message.id_company_group)
                if (foundCompanyGroup) this.selectedCompanyGroup = foundCompanyGroup

                const foundEventType = this.eventTypes.find(i => i.id === message.id_event_type)
                if (foundEventType) this.selectedEventType = foundEventType

                const foundFiscalYear = this.fiscalYears.find(i => i.text === message.fiscal_year)
                if (foundFiscalYear) {
                    this.selectedFiscalYear = foundFiscalYear.text
                    await this.getYearMonths()

                    const foundYearMonth = this.yearMonths.find(i => i.id === message.year_month_input_start)
                    if (foundYearMonth) this.selectedYearMonth = foundYearMonth.text
                }

                this.version = message.version

                let foundEventConfig = this.events.find(i => i.text === message.event_copy_config)
                this.selectedCopyConfigEvent = foundEventConfig

                this.copyEventValuesYear1 = message.copy_event_values_year_1
                if (this.copyEventValuesYear1) {
                    let foundEventYear1 = this.events.find(i => i.text === message.event_copy_values_year_1)
                    this.selectedCopyValuesEventYear1 = foundEventYear1
                }

                this.copyEventValuesYear2 = message.copy_event_values_year_2
                if (this.copyEventValuesYear2) {
                    let foundEventYear2 = this.events.find(i => i.text === message.event_copy_values_year_2)
                    this.selectedCopyValuesEventYear2 = foundEventYear2
                }

                this.isScenarioSimulation = message.scenario_simulation_event

                this.step3Items = message.events.map((item) => {
                    return {
                        account_group_version_description: item.account_group_version_description,
                        copy_event_values_year_1: item.copy_event_values_year_1,
                        copy_event_values_year_2: item.copy_event_values_year_2,
                        copy_event_year_1: this.events.find(i => i.text === item.copy_event_year_1),
                        copy_event_year_2: this.events.find(i => i.text === item.copy_event_year_2),
                        event_description: item.event_description,
                        id_account_group_version: item.id_account_group_version,
                        id_module: item.id_module,
                        id_planning_function: item.id_planning_function,
                        module_description: item.module_description,
                        planning_function: item.planning_function,
                    }
                })

                this.step4Items = message.events.map((item) => {
                    return {
                        account_group_version_description: item.account_group_version_description,
                        end_date: item.end_date,
                        event_description: item.event_description,
                        id_account_group_version: item.id_account_group_version,
                        id_module: item.id_module,
                        id_planning_function: item.id_planning_function,
                        id_status: item.id_status,
                        module_description: item.module_description,
                        planning_function: item.planning_function,
                        start_date: item.start_date,
                        status: this.status.find(i => i.id === item.id_status),
                    }
                })
                
                this.step = 2
                return this.starting = false
            } else {
                this.selectedMainEvent = null
                this.selectedCompanyGroup = null
                this.selectedEventType = null
                this.selectedFiscalYear = null
                this.selectedYearMonth = null
                this.version = null
                this.selectedCopyConfigEvent = null
                this.copyEventValuesYear1 = false
                this.selectedCopyValuesEventYear1 = null
                this.copyEventValuesYear2 = false
                this.selectedCopyValuesEventYear2 = null
                this.isScenarioSimulation = false

                this.step3Items = this.step3Items.map((item) => {
                    return {
                        ...item,
                        copy_event_values_year_1: false,
                        copy_event_values_year_2: false,
                        copy_event_year_1: null,
                        copy_event_year_2: null,
                    }
                })

                this.step4Items = this.step4Items.map((item) => {
                    return {
                        ...item,
                        start_date: null,
                        end_date: null,
                        status: null,
                        id_status: null,
                    }
                })

                this.step = 2
                return this.starting = false
            }
        },

        async getStepsItems() {
            try {
                const res = await this.$http.post(this.$ipEvent + 'planning-function/list-event-assistant', {})
                if (res) {
                    this.step3Items = res.data.rows.map(i => {
                        return {
                            ...i,
                            event_description: '',
                            copy_event_values_year_1: false,
                            copy_event_year_1: null,
                            copy_event_values_year_2: false,
                            copy_event_year_2: null,
                        }
                    })

                    this.step4Items = res.data.rows.map(i => {
                        return {
                            ...i,
                            event_description: '',
                            start_date: null,
                            end_date: null,
                            id_status: null,
                            status: {},
                        }
                    })
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        getTableInfo() {
            let tableInfo = {
                tableName: '',
                tableIcon: '',
            }

            for (const element of this.getMenu) {
                let submenu = element.menus.find(m => m.frontend_route_path === this.$route.path)
                if (submenu) {
                    tableInfo.tableIcon = element.icon
                    tableInfo.tableName = submenu.label
                }
            }

            return tableInfo
        },

        async getEventsAssistant() {
            this.loadingEventAssistant = true

            const filter = {
                conditions: [{
                    column: 'id_message_type',
                    operator: '=',
                    value: 2,
                    AndOr: 'AND'
                }]
            }

            try {
                const res = await this.$http.post(this.$ipMessage + 'message/list', { filter })
                if (res) {
                    this.messages = res.data.rows
                    this.eventsAssistant = res.data.rows.map(i => {
                        return {
                            id: i.id_message,
                            text: JSON.parse(i.message).event
                        }
                    })

                    this.loadingEventAssistant = false
                }
            } catch (err) {
                this.loadingEventAssistant = false
                this.$fnError(err)
            }
        },

        async getEvents() {
            this.loadingEvent = true

            const filter = {
                orderColumn: 'event',
                textColumn: 'event',
                conditions: []
            }
            
            try {
                const res = await this.$http.post(this.$ipEvent + 'event/list-options', { filter })
                if (res) {
                    this.events = res.data.rows
                    this.loadingEvent = false
                }
            } catch (err) {
                this.loadingEvent = false
                this.$fnError(err)
            }
        },

        async getCompanyGroups() {
            this.loadingCompanyGroup = true

            const filter = {
                conditions: []
            }
            
            try {
                const res = await this.$http.post(this.$ipOrganization + 'company-group/list-options', { filter })
                if (res) {
                    this.companyGroups = res.data.rows
                    this.loadingCompanyGroup = false
                }
            } catch (err) {
                this.loadingCompanyGroup = false
                this.$fnError(err)
            }
        },

        async getEventTypes() {
            this.loadingEventType = true

            const filter = {
                conditions: []
            }
            
            try {
                const res = await this.$http.post(this.$ipEvent + 'event-type/list-options', { filter })
                if (res) {
                    this.eventTypes = res.data.rows
                    this.loadingEventType = false
                }
            } catch (err) {
                this.loadingEventType = false
                this.$fnError(err)
            }
        },

        async getStatus() {
            this.loadingStatus = true

            const filter = {
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_status_type',
                        operator: '=',
                        value: 1,
                    }
                ]
            }
            
            try {
                const res = await this.$http.post(this.$ipUnit + 'status/list-options', { filter })
                if (res) {
                    this.status = res.data.rows
                    this.loadingStatus = false
                }
            } catch (err) {
                this.loadingStatus = false
                this.$fnError(err)
            }
        },

        async getFiscalYears() {
            this.loadingFiscalYear = true

            const filter = {
                conditions: []
            }
            
            try {
                const res = await this.$http.post(this.$ipUnit + 'fiscal-year/list-options', { filter })
                if (res) {
                    this.fiscalYears = res.data.rows
                    this.loadingFiscalYear = false
                }
            } catch (err) {
                this.loadingFiscalYear = false
                this.$fnError(err)
            }
        },

        async getYearMonths() {
            this.loadingYearMonth = true

            let filter = {
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'fiscal_year',
                        operator: '=',
                        value: this.selectedFiscalYear,
                    }
                ]
            }
            
            try {
                const res = await this.$http.post(this.$ipUnit + 'calendar/list-options', { filter })
                if (res) {
                    this.yearMonths = res.data.rows
                    this.loadingYearMonth = false
                }
            } catch (err) {
                this.loadingYearMonth = false
                this.$fnError(err)
            }
        },

        formatDate(date) {
            if (!date) {
                return null
            }

            moment.locale('pt-br')
            return moment(date).format('DD/MM/YYYY')
        },

        setInputDate(props, date) {
            if (date.length === 10) {
                let day = date.slice(0, 2)
                let month = date.slice(3, 5) - 1
                let year = date.slice(-4)
                let formattedDate = moment(new Date(year, month, day)).format('YYYY-MM-DD')

                this.step4Items[props.index][props.header.value] = formattedDate
            } else {
                this.step4Items[props.index][props.header.value] = null
            }
        },

        setCopyEventValueYear1(e) {
            this.step3Items.forEach(item => {
                item.copy_event_values_year_1 = e ? true : false
            })
        },

        setCopyEventYear1(e) {
            this.step3Items.forEach(item => {
                item.copy_event_year_1 = e
            })
        },

        setCopyEventValueYear2(e) {
            this.step3Items.forEach(item => {
                item.copy_event_values_year_2 = e ? true : false
            })
        },

        setCopyEventYear2(e) {
            this.step3Items.forEach(item => {
                item.copy_event_year_2 = e
            })
        },

        setEventName() {
            let eventName = this.selectedEventType.text.toUpperCase()
            let version = this.version ? this.version : 'V.0'
            let yearText = this.selectedEventType.id === 2 || this.selectedEventType.id === 3 ? `${this.selectedYearMonth.toString().substr(0, 4)}-${this.selectedYearMonth.toString().substr(4, 6)}` : this.selectedFiscalYear

            return `${eventName}-${yearText}-${version}`
        },

        async submit() {
            this.submiting = true

            let events = this.step3Items.map((item, index) => {
                return {
                    ...item,
                    copy_event_year_1: this.step3Items[index].copy_event_year_1?.text || null,
                    copy_event_year_2: this.step3Items[index].copy_event_year_2?.text || null,
                    start_date: this.step4Items[index].start_date,
                    end_date: this.step4Items[index].end_date,
                    id_status: this.step4Items[index].status?.id || null,
                }
            })

            let payload = {
                event: this.setEventName(),
                create_main_event: this.radioGroup === 1 ? true : false,
                event_main: this.radioGroup === 1 ? null : this.selectedMainEvent.text,
                id_company_group: this.selectedCompanyGroup.id,
                id_event_type: this.selectedEventType.id,
                fiscal_year: this.selectedFiscalYear,
                version: this.version,
                year_month_input_start: this.selectedYearMonth,
                copy_event_config: 1,
                event_copy_config: this.selectedCopyConfigEvent.text,
                copy_event_values_year_1: this.copyEventValuesYear1,
                event_copy_values_year_1: this.copyEventValuesYear1 ? this.selectedCopyValuesEventYear1.text : null,
                copy_event_values_year_2: this.copyEventValuesYear2,
                event_copy_values_year_2: this.copyEventValuesYear2 ? this.selectedCopyValuesEventYear2.text : null,
                scenario_simulation_event: this.isScenarioSimulation,
                events,
            }

            try {
                const res = await this.$http.post(this.$ipEvent + 'event/create-assistant', payload)
                if (res) {
                    this.$toast.success(res.data.msg)
                    this.$router.push('/')
                    this.submiting = false
                }
            } catch (err) {
                this.submiting = false
                this.$fnError(err)
            }
        },
    },
}
</script>
